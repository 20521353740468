<template>
  <g>
    <svg:style>
      .cls-6 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        fill: url(#glass-pattern);
      }
    </svg:style>
    <g id="B01">
      <rect filter="url(#inset-shadow)"
            id="glass"
            class="cls-6"
            :x="`${doorLeftWidth1 + 58.1}`"
            :y="`${doorTopHeight1 + 59.99}`"
            width="25.45"
            height="180.98"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
